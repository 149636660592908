import {signal} from '@preact/signals-react';
import {useParams} from '@remix-run/react';

export const routerParamsSignal = signal<any>(null);

export function GlobalRouterStateUpdater() {
  // explicitly no `useSignals` here because we don't want this to render twice
  const params = useParams();
  routerParamsSignal.value = params;
  return null;
}
